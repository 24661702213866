<div class="tenant-item p-3 d-flex flex-column">
	<div class="d-flex gap-2 mb-3 align-items-center justify-content-start">
		<img [ngSrc]="'assets/images/tenantin-mini-logo.png'" alt="Tenantin website logo" width="89" height="22" />

		<h4 class="heading-4-medium fg-general-3" *ngIf="!tenantData?.viewAppointment" [class.d-none]="fromMyAdsPage">
			{{ 'n3648' | translations }}&nbsp; #{{ index }}
		</h4>

		<h4 class="heading-4-medium fg-prim-2" *ngIf="tenantData.viewAppointment">{{ 'n3676' | translations }}</h4>
	</div>

	<div [ngClass]="tenantData?.viewAppointment ? 'opacity-50' : 'opacity-100'">
		<div class="position-relative avatar">
			<img [ngSrc]="tenantData?.picture || defaultAvatarImg" fill alt="Tenant Image" class="tenant-img d-block" />
			<img [ngSrc]="'assets/images/place-an-ad/on-hunt-badge.webp'" alt="on hunt badge" class="on-hunt-badge" fill />
		</div>

		<h4 class="fg-general-5 heading-4-medium limit-2">{{ tenantData?.title }}</h4>

		<p class="fg-general-4 text-caption-medium mt-3">{{ 'n3649' | translations }}</p>

		<div class="d-flex gap-1 mt-2">
			<p class="fg-general-3 text-caption-regular">{{ 'n3650' | translations }}</p>

			<p class="fg-general-5 text-caption-regular limit-2 me-3">{{ tenantData?.employment?.jobTitle }}</p>
		</div>

		<div class="row gap-1 align-items-start mt-2">
			<div class="col-auto">
				<p class="fg-general-3 text-caption-regular">{{ 'n3651' | translations }}</p>
			</div>

			<div class="col">
				<p class="fg-general-5 text-caption-regular">{{ tenantData?.employment?.grossIncome?.name }}</p>
			</div>
		</div>

		<div class="row gap-1 align-items-start mt-2">
			<div class="col-auto">
				<p class="fg-general-3 text-caption-regular">{{ 'n3652' | translations }}</p>
			</div>

			<div class="col">
				<p class="fg-general-5 text-caption-regular limit-2 me-3">{{ tenantData?.tenantinQuality | tenantQuality }}</p>
			</div>
		</div>

		<div class="row gap-1 mt-2">
			<div class="col-auto">
				<p class="fg-general-3 text-caption-regular">{{ 'n3653' | translations }}</p>
			</div>

			<div class="col">
				<p class="fg-general-5 text-caption-regular limit-2 me-3">
					{{ tenantData | tenantOtherInfo }}
				</p>
			</div>
		</div>
	</div>

	<ng-template #showScheduleBtn>
		<button
			mat-stroked-button
			color="primary"
			class="text-body-regular w-100 mt-auto mb-0 mw-343px"
			(click)="onScheduleView()"
			[class.pe-none]="isDisableScheduleBtn"
		>
			Schedule a Viewing
		</button>
	</ng-template>

	<div class="view-date mt-auto mb-0 p-2" *ngIf="tenantData?.viewAppointment; else showScheduleBtn">
		<p class="text-body-regular fg-general-4 mb-1">
			{{ 'n3647' | translations }}
			<span class="text-body-medium fg-general-5 ms-1">{{ +tenantData?.viewAppointment?.viewingAt * 1000 | date: 'mediumDate' }}</span>
		</p>

		<p class="text-body-regular fg-general-4">
			{{ 'n3647' | translations }}
			<span class="text-body-medium fg-general-5 ms-1">{{ tenantData?.viewAppointment?.slotTime?.name }}</span>
		</p>
	</div>
</div>
