import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { MatchingTenantItemComponent } from './matching-tenant-item.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [MatchingTenantItemComponent],
	imports: [SharedModule, MatIconModule],
	exports: [MatchingTenantItemComponent],
})
export class MatchingTenantItemModule {}
