import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tenant } from '@core/models';
import { environment } from '@environments/environment';

@Component({
	selector: 'findqo-matching-tenant-item',
	templateUrl: './matching-tenant-item.component.html',
	styleUrls: ['./matching-tenant-item.component.scss'],
})
export class MatchingTenantItemComponent {
	@Input() tenantData: Tenant;
	@Input() index: number;
	readonly defaultAvatarImg = environment.defaultAvatarImg;
	@Output() openTenant = new EventEmitter<Tenant>();
	@Input() fromMyAdsPage = false;
	@Input() isDisableScheduleBtn = false;

	onScheduleView(): void {
		this.openTenant.emit(this.tenantData);
	}
}
